import React from 'react'
import MedicineTemplate from 'components/templates/MedicineTemplate/MedicineTemplate'

export default function MedicineHealthSurveillancePage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Sorveglianza Sanitaria'

  const description =
    'I lavoratori per i quali la valutazione dei rischi ha evidenziato un rischio per la salute devono essere  sottoposti a sorveglianza sanitaria (art. 242 d.lgs. 81/2008).<br> Il medico competente stabilisce il programma di sorveglianza sanitaria ed epidemiologica e lo attua secondo criteri e protocolli basati sull’evidenza.<br> Gli accertamenti preventivi sono orientati a constatare l’assenza di controindicazioni alla mansione specifica che il lavoratore deve svolgere.<br><br> Lo scopo della sorveglianza sanitaria è quello di:<br><br> • Valutare l’idoneità specifica al lavoro<br> • Scoprire in tempo utile anomalie cliniche o precliniche (diagnosi precoce)<br> • Prevenire peggioramenti della salute del lavoratore (prevenzione secondaria)<br> • valutare l’efficacia delle misure preventive nel luogo di lavoro<br> • Rafforzare misure e comportamenti lavorativi corretti.<br><br> ING GROUP, attraverso la sua struttura, articolata su più sedi su tutto il territorio nazionale, la disponibilità di un software di settore unitamente ad una segreteria tecnica di provata esperienza, gestisce la sorveglianza sanitaria di aziende di produzione e di servizi (vigilanza, pulizie, portierato, facchinaggio, grande distribuzione, etc) anche con condizioni proibitive di esercizio quali più sedi sul territorio e turnazioni h 24.'

  return (
    <>
      <MedicineTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
